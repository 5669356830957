<template>
  <div>
    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <div class="row ml-2 mb-2" style="display: block ruby">
        <h4>Liste des marques</h4>
        <b-button
          type="submit"
          class="btn ml-2"
          variant="outline-primary"
          @click="RedirectToAddMark"
        >
          Ajouter
        </b-button>
      </div>
      <div>
        <b-table
          :items="listeMarques.marques"
          :fields="elementMarques"
          striped
          hover
          responsive
          :busy="isBusy"
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong style="margin-left: 5px">Loading...</strong>
            </div>
          </template>
          <template #cell(image)="row" style="width: 100px">
            <div v-if="row.item.image">
              <b-img :src="row.item.image.src" width="50"> </b-img>
            </div>
            <div v-else>
              <b-img :src="marqueImageNotFound" width="50"> </b-img>
            </div>
          </template>
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <div class="text-nowrap">
              <feather-icon
                :id="`invoice-row-${data.item.id}-preview-icon`"
                :icon="data.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
                size="16"
                class="mx-1"
                @click.stop="getSousMarque(data.item.id)"
                @click="data.toggleDetails"
              />
              <b-tooltip
                title="Sous marques"
                :target="`invoice-row-${data.item.id}-preview-icon`"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-edit-icon`"
                icon="EditIcon"
                @click="RedirectToEditMarque(data.item.id)"
              />
              <b-tooltip
                title="Modification"
                :target="`invoice-row-${data.item.id}-edit-icon`"
              />
              <feather-icon
                :id="`invoice-row-${data.item.id}-quick-edit-icon`"
                icon="EditIcon"
                class="mx-1"
                @click="getMarqueInfo(data.item)"
              />
              <b-tooltip
                title="Modification rapide"
                :target="`invoice-row-${data.item.id}-quick-edit-icon`"
              />

              <feather-icon
                :id="`invoice-row-${data.item.id}-trash-icon`"
                icon="TrashIcon"
                @click="deleteAction(data.item.id)"
              />
              <b-tooltip
                title="Supprimer"
                :target="`invoice-row-${data.item.id}-trash-icon`"
              />
            </div>
          </template>
          <template #row-details="row">
            <b-row class="mb-2">
              <b-table
                :items="ListSousMarques"
                :fields="elementMarques"
                striped
                hover
                responsive
                :busy="loadSubMarque"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong style="margin-left: 5px">Loading...</strong>
                  </div>
                </template>
                <template #cell(image)="row" style="width: 100px">
                  <div v-if="row.item.image">
                    <b-img :src="row.item.image.src" width="50"> </b-img>
                  </div>
                  <div v-else>
                    <b-img :src="marqueImageNotFound" width="50"> </b-img>
                  </div>
                </template>
                <template #cell(actions)="data">
                  <div class="text-nowrap">
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-edit-icon`"
                      icon="EditIcon"
                      @click="RedirectToEditMarque(data.item.id)"
                    />
                    <b-tooltip
                      title="Modification"
                      :target="`invoice-row-${data.item.id}-edit-icon`"
                    />
                    <feather-icon
                      :id="`invoice-row-${data.item.id}-quick-edit-icon`"
                      icon="EditIcon"
                      class="mx-1"
                      @click="getMarqueInfo(data.item)"
                    />
                    <b-tooltip
                      title="Modification rapide"
                      :target="`invoice-row-${data.item.id}-quick-edit-icon`"
                    />

                    <feather-icon
                      :id="`invoice-row-${data.item.id}-trash-icon`"
                      icon="TrashIcon"
                      @click="deleteAction(data.item.id)"
                    />
                    <b-tooltip
                      title="Supprimer"
                      :target="`invoice-row-${data.item.id}-trash-icon`"
                    />
                  </div>
                </template>
              </b-table>
            </b-row>
          </template>
        </b-table>
        <b-pagination
          class="mt-3"
          align="center"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="listeMarques.totalMarques"
          @change="actualisationMarques"
        ></b-pagination>
      </div>
      <div>
        <QuickEditMarque
          v-if="showEditModal"
          :showModal="showEditModal"
          :name="marqueInfo.name"
          :slug="marqueInfo.slug"
          @ConfirmEdit="quickUpdateMarque"
        />
      </div>
    </b-overlay>
  </div>
</template>
<script>
import {
  BButton,
  BIcon,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BBadge,
  BAvatar,
  BTable,
  BSpinner,
  BImg,
  BLink,
  BTooltip,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BFormSelect,
  BFormInput,
  BPagination,
} from "bootstrap-vue";
import axios from "axios";
import websiteURL from "@/websiteURL";
import marqueImageNotFound from "../../assets/images/logo/woocommerce-placeholder.png";
import { getListeMarque } from "../../views/Marques/MarqueFunction.js";
import QuickEditMarque from "../../views/QuickEditModal/QuickEdit.vue";
export default {
  components: {
    BSpinner,
    BTooltip,
    BDropdownItem,
    BDropdown,
    BIcon,
    BTable,
    BImg,
    BLink,
    BButton,
    BFormCheckbox,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BFormSelect,
    BFormInput,
    BOverlay,
    BPagination,
    QuickEditMarque,
  },
  data() {
    return {
      isBusy: false,
      mainProps: {
        width: 75,
        height: 75,
        class: "m1",
      },
      listeMarques: [],
      elementMarques: [
        {
          label: "Image",
          key: "image",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Nom",
          key: "name",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Slug",
          key: "slug",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Total produits",
          key: "count",
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          label: "Actions",
          key: "actions",
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],

      marqueImageNotFound: marqueImageNotFound,
      deleteMsg: "",
      loading: false,
      ListSousMarques: [],
      perPage: 5,
      currentPage: 1,
      loadSubMarque: false,
      showEditModal: false,
      marqueInfo: [],
      nomMarque: "",
      slugMarque: "",
    };
  },

  methods: {
    async RedirectToEditMarque(item) {
      this.$router.push({
        name: "Edit Marque",
        params: { id: item },
      });
    },
    async RedirectToAddMark() {
      this.$router.push({
        name: "Add Marque",
        params: {},
      });
    },
    //Recuperation des sous marques
    async getSousMarque(idMarque) {
      this.loadSubMarque = true;
      const apiUrl = websiteURL.WEBSITE + `wp-json/wc/v3/products/brands`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.get(apiUrl, {
          params: {
            parent: idMarque,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });

        this.loadSubMarque = false;
        this.ListSousMarques = response.data;
      } catch (error) {
        alert(
          "Erreur lors de la récuperation des sous marques, veuillez réessayer plus tard",
          error
        );
      }
    },
    //Permet l'actualisation de la liste des marques
    async actualisationMarques(event) {
      this.isBusy = true;
      if (event === null) {
        this.listeMarques = await getListeMarque(
          this.perPage,
          this.currentPage
        );
      } else {
        this.listeMarques = await getListeMarque(this.perPage, event);
      }
      this.isBusy = false;
    },
    deleteAction(idMarque) {
      this.marqueId = idMarque;
      this.dialogueSuppressionMarque();
    },
    //Permet la suppression d'une marque
    async deleteMarque() {
      const apiUrl =
        websiteURL.WEBSITE + `wp-json/wc/v3/products/brands/${this.marqueId}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      try {
        const response = await axios.delete(apiUrl, {
          params: {
            force: true,
          },
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        this.msgConfirmationSuppression("info");
        this.currentPage = 1;
      } catch (error) {
        alert(
          "Erreur lors de la suppression de la marque, veuillez réessayer plus tard",
          error
        );
      }
    },
    //popup pour confirmer ou non la suppression d'une marque
    dialogueSuppressionMarque() {
      this.deleteMsg = "";
      this.$bvModal
        .msgBoxConfirm("Êtes-vous sûr de vouloir supprimer la marque ?", {
          title: "Confirmation",
          size: "sm",
          okVariant: "primary",
          okTitle: this.loading ? "Loading..." : "Confirmer",
          cancelTitle: "Annuler",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          this.deleteMsg = value;
          if (value) {
            if (value) {
              this.loading = true;
              this.deleteMarque().then(async () => {
                this.loading = false;
                await this.actualisationMarques();
              });
            }
          }
        });
    },
    //Message indiquant que la suppression est réussi
    msgConfirmationSuppression(variant = null) {
      this.$bvToast.toast("La marque a bien été supprimer", {
        title: `Marque ${variant || "default"}`,
        variant,
        solid: true,
        href: this.permalink,
      });
    },
    //Permet de recuperer les informations d'une marque au clique sur icon modification rapide
    async getMarqueInfo(item) {
      this.marqueInfo = item;
      this.nomMarque = this.marqueInfo.name;
      this.slugMarque = this.marqueInfo.slug;
      this.showEditModal = !this.showEditModal;
    },
    //Permet la modification rapide d'une marque
    async quickUpdateMarque(marque) {
      const apiUrl =
        websiteURL.WEBSITE +
        `wp-json/wc/v3/products/brands/${this.marqueInfo.id}`;
      const consumerKey = websiteURL.CONSUMERKEY;
      const consumerSecret = websiteURL.CONSUMERSECRET;
      const data = {
        name: marque.name,
        slug: marque.slug,
      };
      try {
        const response = await axios.put(apiUrl, data, {
          auth: {
            username: consumerKey,
            password: consumerSecret,
          },
        });
        await this.actualisationMarques();
        this.currentPage = 1;
      } catch (error) {
        // Handle errors, e.g., show an error message
        alert("Erreur lors de la mise à jour de la marque:", error);
      }
    },
  },
  async created() {
    await this.actualisationMarques();
  },
};
</script>
